<template>
  <div class="">
    <div class="">
      <form
        @submit.prevent="
          getAll(DEFAULT_URL, {
            tableLoader: { enabled: false },
            searchLoader: { enabled: true },
          })
        "
      >
        <div class="input-group col-4 px-0">
          <select
            name=""
            id=""
            class="form-control col-auto"
            v-model="params.column"
            @change="changeInputType"
          >
            <option value="" hidden selected disabled>Choose</option>
            <option value="cav.id">App No</option>
            <option value="lastname">Last Name</option>
            <option value="firstname">First Name</option>
            <option value="middlename">Middle Name</option>
            <option value="cav.created_at">Date Applied</option>
            <option value="cav.school_graduated">HEI Abbrev.</option>
          </select>

          <input
            v-if="
              params.column !== 'cav.school_graduated' &&
                params.column !== 'cav.created_at'
            "
            id="search-input"
            type="search"
            class="form-control"
            placeholder="Search"
            autocomplete="on"
            v-model="params.value"
          />

          <select
            v-if="params.column == 'cav.school_graduated'"
            v-model="params.value"
            name=""
            class="form-control"
            id=""
          >
            <option selected disabled hidden value="">Choose</option>
            <option v-for="hei in heis" :key="hei.code" :value="hei.code">
              {{ hei.abbrev }}</option
            >
          </select>

          <input
            type="date"
            class="form-control"
            v-model="params.value"
            v-if="params.column == 'cav.created_at'"
          />

          <div class="input-group-append">
            <button
              id="cav-app-search-btn"
              type="submit"
              class="btn btn-outline-secondary"
            >
              <span v-if="!searchLoader">
                <i class="bi bi-search text-dark"></i
              ></span>
              <span v-else class="spinner-border spinner-border-sm"></span>
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="d-flex justify-content-between mt-3">
      <div class="form-inline">
        <div class="form-group">
          <label for="">No. of rows</label>
          <select
            name=""
            id=""
            class="form-control form-control-sm ml-2"
            v-model="per_page"
            @change="
              getAll(DEFAULT_URL, {
                tableLoader: { enabled: false },
                searchLoader: { enabled: false },
              })
            "
          >
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
      <div class="d-flex justify-content-right align-items-baseline">
        <div class="my-auto mr-2">
          <span
            >{{
              `${pagination.from || 0}-${pagination.to ||
                0} of ${pagination.total || 0}`
            }}
          </span>
        </div>
        <nav>
          <ul class="pagination mb-0 pagination-sm">
            <li
              class="page-item"
              :class="{ disabled: !pagination.prev_page_url }"
            >
              <a
                class="page-link"
                href="#"
                @click.prevent="
                  getAll(pagination.prev_page_url, {
                    tableLoader: { enabled: false },
                    searchLoader: { enabled: false },
                  })
                "
              >
                <span aria-hidden="true"
                  ><i class="bi bi-chevron-left"></i
                ></span>
                <span class="sr-only">Previous</span>
              </a>
            </li>

            <li
              class="page-item"
              :class="{ disabled: !pagination.next_page_url }"
            >
              <a
                class="page-link"
                href="#"
                @click.prevent="
                  getAll(pagination.next_page_url, {
                    tableLoader: { enabled: false },
                    searchLoader: { enabled: false },
                  })
                "
              >
                <span aria-hidden="true"
                  ><i class="bi bi-chevron-right"></i
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="table-responsive">
      <table
        id="apps-all-table"
        class="table table-sm table-bordered table-hover mt-3"
      >
        <thead>
          <th>APP NO</th>
          <th>NAME</th>
          <th>TYPE</th>
          <th>HEI</th>
          <th>PURPOSE</th>
          <th>DATE APPLIED</th>
          <th>CTC</th>
          <th>LAST UPDATED BY</th>
          <th>RELEASED TO</th>
          <th>STATUS</th>
        </thead>

        <tbody class="text-uppercase">
          <tr v-show="tableLoader" class="text-center">
            <td colspan="15">
              <div class="spinner-border spinner-border-sm mr-2"></div>
              Loading...
            </td>
          </tr>
          <tr v-if="!apps.length && !tableLoader" class="text-center">
            <td colspan="15">No results found.</td>
          </tr>
          <tr
            v-for="app in apps"
            :key="app.id"
            @click.stop="openAppDetailsModal(app)"
          >
            <th>{{ app.id }}</th>

            <td class="text-left">
              {{ app.firstname }} {{ app.middlename }} {{ app.lastname }}
            </td>
            <td>{{ app.student_type }}</td>
            <td>{{ app.abbrev }}</td>
            <td>{{ app.purpose }}</td>
            <td>{{ app.created_at | dateTime }}</td>
            <td class="text-right">{{ app.ctc_qty }}</td>
            <td>
              <span v-show="app.updated_by">{{ app.updated_by }}</span>
            </td>
            <td>
              <span v-show="app.received_by">{{ app.received_by }} </span>
            </td>
            <td>
              <span :class="textClass(app.status_id)">{{ app.status }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <AppDetailsModal ref="AppDetailsModal" :editable="false"></AppDetailsModal>
  </div>
</template>

<script>
import AppDetailsModal from './AppDetailsModal.vue';
import SearchBar from './SearchBar.vue';

export default {
  name: 'AllTransactions',
  components: {
    AppDetailsModal,
    SearchBar,
  },
  data() {
    return {
      count: '...',
      file: '',
      apps: [],
      heis: [],
      currentID: '',
      tableLoader: false,
      searchLoader: false,
      pagination: {},
      per_page: 10,
      DEFAULT_URL: '/api/all-transactions',
      params: {
        value: '',
        column: 'lastname',
      },
    };
  },
  methods: {
    getAll(page_url, options) {
      this.tableLoader = options.tableLoader.enabled;
      this.searchLoader = options.searchLoader.enabled;
      page_url = page_url || this.DEFAULT_URL;

      axios
        .get(page_url, {
          params: {
            value: this.params.value,
            column: this.params.column,
            per_page: this.per_page,
          },
        })
        .then((res) => {
          this.apps = res.data.data;
          this.makePagination(res.data);
          this.tableLoader = false;
          this.searchLoader = false;
        })
        .catch((err) => {
          this.tableLoader = false;
          this.searchLoader = false;
        });
    },

    getHeis() {
      axios
        .get('api/heis')
        .then((response) => {
          this.heis = response.data;
        })
        .catch((error) => {
          toastr.error('Oops! Something went wrong.', 'Error');
        });
    },

    openAppDetailsModal(data) {
      this.$refs.AppDetailsModal.openModal(data);
    },

    makePagination(data) {
      let pagination = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url,
        from: data.from,
        to: data.to,
        total: data.total,
      };

      this.pagination = pagination;
    },

    textClass(status) {
      switch (status) {
        case 1:
          return 'badge badge-secondary';
        case 2:
          return 'badge badge-secondary';
        case 3:
          return 'badge badge-secondary';
        case 4:
          return 'badge badge-secondary';
        case 5:
          return 'badge badge-secondary';
        case 6:
          return 'badge badge-success';
        case 7:
          return 'badge badge-danger';
        case 8:
          return 'badge badge-danger';
      }
    },
  },

  mounted() {
    this.getAll(this.DEFAULT_URL, {
      tableLoader: { enabled: true },
      searchLoader: { enabled: false },
    });

    this.getHeis();
  },
};
</script>

<style scoped>
#apps-all-table tr {
  cursor: pointer !important;
}
</style>

<template>
  <div class="">
    <div class="row mb-4">
      <div class="col-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn btn-outline-secondary text-dark" @click="clear">
              <i class="bi bi-arrow-clockwise text-dark"></i>
            </button>
          </div>
          <select
            name=""
            id=""
            class="form-control"
            @change="changeInputType"
            v-model="column"
          >
            <option value="lastname">Last Name</option>
            <option value="firstname">First Name</option>
            <option value="created_at">Date Applied</option>
            <option value="abbrev">Institution</option>
          </select>
          <input
            id="search-input"
            type="search"
            class="form-control"
            placeholder="Search"
            v-model="searchValue"
          />
        </div>
      </div>

      <div class="col-auto px-2">
        <button
          @click="
            getForValidation({
              tableLoader: { enabled: false },
              reloadLoader: { enabled: true },
            })
          "
          class="btn btn-outline-secondary text-dark"
        >
          <i class="bi bi-arrow-repeat mr-1"></i> Reload
          <div
            v-if="reloadLoader"
            class="spinner-border spinner-border-sm mx-1"
            role="status"
          >
            <span class="invisible">Loading...</span>
          </div>
        </button>
      </div>

      <div class="col-auto px-2">
        <ActionsDropdown
          :selectedApps="selectedApps"
          @updateTable="
            getForValidation({
              tableLoader: { enabled: false },
              reloadLoader: { enabled: false },
            })
          "
          :statusId="1"
        ></ActionsDropdown>
      </div>

      <div class="col-auto px-2">
        <button
          @click.prevent="openApplicationForm"
          class="btn btn-outline-secondary text-dark"
        >
          <i class="bi bi-person-plus mr-2"></i>Walk-in application
        </button>
      </div>
    </div>

    <table
      id="apps-for-validation-table"
      class="table table-sm table-bordered table-hover"
    >
      <thead>
        <th>
          <input
            type="checkbox"
            v-model="checked"
            class="large-checkbox"
            @change="checked ? selectAll() : unselectAll()"
          />
        </th>
        <th>APP NO</th>
        <th>NAME</th>
        <th>TYPE</th>
        <th>HEI</th>
        <th>PURPOSE</th>
        <th>DATE APPLIED</th>
        <th>CTC</th>
        <th>STATUS</th>
      </thead>

      <tbody class="text-uppercase">
        <tr v-show="tableLoader" class="text-center">
          <td colspan="14">
            <div class="spinner-border spinner-border-sm mr-2"></div>
            Loading...
          </td>
        </tr>
        <tr v-if="!filteredApps.length && !tableLoader" class="text-center">
          <td colspan="14">No results found.</td>
        </tr>
        <tr
          v-for="app in filteredApps"
          :key="app.id"
          @click.stop="openAppDetailsModal(app)"
          :class="{
            'table-primary': selectedApps.some((obj) => obj.id == app.id),
          }"
        >
          <td>
            <input
              type="checkbox"
              class="large-checkbox"
              v-model="selectedApps"
              :value="app"
              @click.stop
            />
          </td>
          <th>
            <OverdueIndicator :created_at="app.created_at"> </OverdueIndicator>
            {{ app.id }}
          </th>
          <td>{{ app.firstname }} {{ app.middlename }} {{ app.lastname }}</td>
          <td>{{ app.student_type }}</td>
          <td>{{ app.abbrev }}</td>
          <td>{{ app.purpose }}</td>
          <td>{{ app.created_at | dateTime }}</td>
          <td class="text-right">{{ app.ctc_qty }}</td>
          <td>
            <span class="badge badge-secondary">{{ app.status }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <AppDetailsModal ref="AppDetailsModal" :editable="true"></AppDetailsModal>

    <ApplicationFormModal
      ref="ApplicationFormModal"
      v-on:getApps="
        getForValidation({
          tableLoader: { enabled: false },
          reloadLoader: { enabled: false },
        })
      "
    ></ApplicationFormModal>
  </div>
</template>

<script>
import AppDetailsModal from './AppDetailsModal.vue';
import ApplicationFormModal from '../../../CAVApplication/components/ApplicationFormModal.vue';
import ActionsDropdown from './ActionsDropdown.vue';
import OverdueIndicator from './OverdueIndicator.vue';

export default {
  name: 'ForValidation',
  components: {
    AppDetailsModal,
    ApplicationFormModal,
    ActionsDropdown,
    OverdueIndicator,
  },
  data() {
    return {
      column: 'lastname',
      searchValue: '',
      selectedApps: [],
      checked: false,
      file: '',
      apps: [],
      currentID: '',
      tableLoader: false,
      reloadLoader: false,
      form: new Form({
        file_type: '',
        file: '',
      }),
    };
  },
  methods: {
    getForValidation(config) {
      this.tableLoader = config.tableLoader.enabled;
      this.reloadLoader = config.reloadLoader.enabled;

      axios
        .get('/api/cav-applications/filter/1')
        .then((res) => {
          this.apps = res.data;
          this.tableLoader = false;
          this.reloadLoader = false;
          this.selectedApps = [];
        })
        .catch((err) => {
          this.tableLoader = false;
          this.reloadLoader = false;
        });
    },
    changeInputType() {
      this.searchValue = '';
      let input = document.getElementById('search-input');
      this.column == 'created_at'
        ? (input.type = 'date')
        : (input.type = 'text');
    },
    clear() {
      this.column = '';
      this.searchValue = '';
    },
    openAppDetailsModal(data, index) {
      this.$refs.AppDetailsModal.openModal(data, index);
    },

    openApplicationForm() {
      this.$refs.ApplicationFormModal.open();
    },

    selectAll() {
      this.selectedApps = [];
      this.filteredApps.forEach((student) => {
        this.selectedApps.push(student);
      });
    },

    unselectAll() {
      this.selectedApps = [];
    },
  },

  computed: {
    filteredApps() {
      let temp = this.apps;
      if (this.searchValue != '') {
        temp = temp.filter((cav) => {
          return cav[this.column]
            .toUpperCase()
            .includes(this.searchValue.toUpperCase());
        });
      }

      return temp;
    },
  },
  mounted() {
    this.getForValidation({
      tableLoader: { enabled: true },
      reloadLoader: { enabled: false },
    });

    this.$emit('getStats');
  },
};
</script>

<style scoped>
#apps-for-validation-table tr {
  cursor: pointer !important;
}
</style>

<template>
  <div class="mt-4">
    <div class="container">
      <div v-if="loader" class="d-flex justify-content-center">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>
      <ul class="timeline mx-2">
        <li v-for="log in logs" :key="log.id">
          <p class="mb-0">{{ log.description }}</p>
          <span class="text-muted form-text mt-0" style="font-size: 12.5px">
            By: {{ log.updated_by }} - {{ log.created_at | completeDateTime }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusLog',
  props: {
    app: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      loader: false,
      logs: [],
    };
  },
  methods: {
    getStatusLogs() {
      this.loader = true;

      axios
        .get('api/cav-app-status-logs/' + this.app.id)
        .then((res) => {
          this.logs = res.data;
          this.loader = false;
        })
        .catch(() => {
          toastr.error('Something went wrong.', 'Error');
          this.loader = false;
        });
    },
  },
  mounted() {
    this.getStatusLogs();
  },
};
</script>

<style scoped>
ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #bcc0c5;
  display: inline-block;
  position: absolute;
  left: 7px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 0px;
}
ul.timeline > li:before {
  content: '';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #bcc0c5;
  left: 0px;

  width: 15px;
  height: 15px;
  z-index: 400;
}
</style>

<template>
  <div class="">
    <div class="row mb-4">
      <div class="col-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn btn-outline-secondary text-dark" @click="clear">
              <i class="bi bi-arrow-clockwise text-dark"></i>
            </button>
          </div>
          <select
            name=""
            id=""
            class="form-control"
            @change="changeInputType"
            v-model="column"
          >
            <option value="lastname">Last Name</option>
            <option value="firstname">First Name</option>
            <option value="created_at">Date Applied</option>
            <option value="abbrev">Institution</option>
          </select>
          <input
            id="search-input"
            type="text"
            class="form-control"
            placeholder="Search"
            v-model="searchValue"
          />
        </div>
      </div>
      <!--    <div class="col-auto px-1">
        <button class="btn btn-primary mr-2" @click="showBulkValidateModal">
          Bulk Validate
        </button>
      </div> -->

      <div class="col-auto px-2">
        <button
          @click="
            getForPayment({
              tableLoader: { enabled: false },
              reloadLoader: { enabled: true },
            })
          "
          class="btn btn-outline-secondary text-dark"
        >
          <i class="bi bi-arrow-repeat mr-1"></i> Reload

          <div
            v-if="reloadLoader"
            class="spinner-border spinner-border-sm mx-1"
            role="status"
          >
            <span class="invisible">Loading...</span>
          </div>
        </button>
      </div>

      <div class="col-auto px-2">
        <ActionsDropdown
          :selectedApps="selectedApps"
          @updateTable="
            getForPayment({
              tableLoader: { enabled: false },
              reloadLoader: { enabled: false },
            })
          "
          :statusId="2"
        ></ActionsDropdown>
      </div>
    </div>
    <table
      id="apps-for-payment-validation-table"
      class="table table-sm table-bordered table-hover"
    >
      <thead class="">
        <th>
          <input
            type="checkbox"
            v-model="checked"
            class="large-checkbox"
            disabled
            @change="checked ? selectAll() : unselectAll()"
          />
        </th>
        <th>APP NO</th>
        <th>NAME</th>
        <th>TYPE</th>
        <th>HEI</th>
        <th>PURPOSE</th>
        <th>DATE APPLIED</th>
        <th>CTC</th>
        <th>UPDATED BY</th>
        <th>STATUS</th>
        <th>ACTIONS</th>
      </thead>

      <tbody class="text-uppercase">
        <tr v-show="tableLoader" class="text-center">
          <td colspan="13">
            <div class="spinner-border spinner-border-sm mr-2"></div>
            Loading...
          </td>
        </tr>
        <tr v-if="!filteredApps.length && !tableLoader" class="text-center">
          <td colspan="13">No results found.</td>
        </tr>
        <tr
          v-for="app in filteredApps"
          :key="app.id"
          @click.stop="openAppDetailsModal(app)"
        >
          <td>
            <input
              type="checkbox"
              class="large-checkbox"
              v-model="selectedApps"
              :value="app"
              @click.stop
              disabled
            />
          </td>
          <th>
            <OverdueIndicator :created_at="app.created_at"> </OverdueIndicator>
            {{ app.id }}
          </th>
          <td>{{ app.firstname }} {{ app.middlename }} {{ app.lastname }}</td>
          <td>{{ app.student_type }}</td>
          <td>{{ app.abbrev }}</td>
          <td>{{ app.purpose }}</td>
          <td>{{ app.created_at | dateTime }}</td>
          <td class="text-right">{{ app.ctc_qty }}</td>
          <td>{{ app.updated_by }}</td>
          <td>
            <span class="badge badge-danger">{{ app.status }}</span>
          </td>
          <td>
            <div class="btn-group">
              <button
                class="btn btn-outline-secondary btn-sm"
                @click.stop="openPaymentValidationModal(app)"
              >
                <i class="bi bi-pencil text-primary"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <PaymentValidation
      ref="PaymentValidation"
      @updateTable="
        getForPayment({
          tableLoader: { enabled: false },
          reloadLoader: { enabled: false },
        })
      "
    ></PaymentValidation>
    <AppDetailsModal ref="AppDetailsModal" :editable="false"> </AppDetailsModal>
  </div>
</template>

<script>
import PaymentValidation from './PaymentValidation.vue';
import AppDetailsModal from './AppDetailsModal.vue';
import ActionsDropdown from './ActionsDropdown.vue';
import OverdueIndicator from './OverdueIndicator.vue';

export default {
  name: 'ForPayment',
  components: {
    PaymentValidation,
    AppDetailsModal,
    ActionsDropdown,
    OverdueIndicator,
  },
  data() {
    return {
      searchValue: '',
      file: '',
      apps: [],
      selectedApps: [],
      checked: false,
      currentID: '',
      tableLoader: false,
      reloadLoader: false,
      column: 'lastname',
    };
  },
  methods: {
    getForPayment(config) {
      this.tableLoader = config.tableLoader.enabled;
      this.reloadLoader = config.reloadLoader.enabled;

      axios
        .get('/api/cav-applications/filter/2')
        .then((res) => {
          this.apps = res.data;
          this.tableLoader = false;
          this.reloadLoader = false;
          this.selectedApps = [];
        })
        .catch((err) => {
          this.tableLoader = false;
          this.reloadLoader = false;
        });
    },

    changeInputType() {
      this.searchValue = '';
      let input = document.getElementById('search-input');
      this.column == 'created_at'
        ? (input.type = 'date')
        : (input.type = 'text');
    },
    clear() {
      this.column = '';
      this.searchValue = '';
    },

    openAppDetailsModal(data, index) {
      this.$refs.AppDetailsModal.openModal(data, index);
    },

    openPaymentValidationModal(data, index) {
      this.$refs.PaymentValidation.openModal(data, index);
    },

    selectAll() {
      this.selectedApps = [];
      this.filteredApps.forEach((student) => {
        this.selectedApps.push(student);
      });
    },

    unselectAll() {
      this.selectedApps = [];
    },
  },

  computed: {
    filteredApps() {
      let temp = this.apps;

      if (this.searchValue != '') {
        temp = temp.filter((cav) => {
          return cav[this.column]
            .toUpperCase()
            .includes(this.searchValue.toUpperCase());
        });
      }

      return temp;
    },
  },
  mounted() {
    this.getForPayment({
      tableLoader: { enabled: true },
      reloadLoader: { enabled: false },
    });

    this.$emit('getStats');
  },
};
</script>

<style scoped>
#apps-for-payment-validation-table tr {
  cursor: pointer !important;
}
</style>

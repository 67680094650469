<template>
  <div>
    <div class="modal" data-backdrop="static" id="payment-details-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="updatePaymentStatus">
            <div class="modal-body">
              <div class="form-group">
                <label for="">Name</label>
                <h5 class="text-uppercase font-weight-bold">
                  {{ form.firstname }} {{ form.middlename }}
                  {{ form.lastname }}
                </h5>
                <p>
                  APP NO: <strong>{{ form.id }} - {{ form.abbrev }}</strong>
                </p>
              </div>

              <div class="form-group">
                <label for="">Payment Status</label>
                <select name="" class="form-control" v-model="updatedStatusId">
                  <option value="" disabled hidden selected>Choose</option>
                  <option value="3">Mark as Paid</option>
                  <option value="8">Pending Payment</option>
                </select>
              </div>
              <div v-if="updatedStatusId == 3">
                <div class="form-row form-group">
                  <div class="col ">
                    <label for="">CAV Control No</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="form.control_no"
                      maxlength="6"
                      required
                    />
                  </div>

                  <div class="col">
                    <label for="">Transaction Amount</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.amount"
                      required
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label for="">LBP Confirmation No</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.receipt_no"
                    maxlength="20"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="">LBP Reference No</label>

                  <input
                    type="text"
                    class="form-control"
                    v-model="form.reference_no"
                    maxlength="20"
                    required
                  />
                </div>

                <div v-show="spinner" class="mb-2">
                  <div class="spinner-border spinner-border-sm mr-2"></div>
                  Searching..
                </div>
                <div class="form-group">
                  <label for="">Transaction Date</label>
                  <div class="input-group">
                    <input
                      type="date"
                      class="form-control"
                      v-model="form.receipt_date"
                      required
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="">Deficiences/Remarks</label>
                <textarea
                  class="form-control"
                  id=""
                  rows="3"
                  v-model="form.payment_remarks"
                ></textarea>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                CLOSE
              </button>
              <button
                id="update-payment-btn"
                type="submit"
                class="btn btn-primary"
                :disabled="!updatedStatusId"
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      prefix: 'https://drive.google.com/file/d/',
      spinner: false,
      checkbox: false,
      updatedStatusId: '',
      linkToAppId: '',
      app: '',
      form: new Form({
        id: '',
        firstname: '',
        lastname: '',
        middlename: '',
        payment_id: '',
        status_id: '',
        receipt_no: '',
        control_no: '',
        reference_no: '',
        abbrev: '',
        amount: '',
        payment_remarks: '',
      }),
    };
  },
  methods: {
    openModal(app) {
      this.updatedStatusId = '';
      this.form.reset();
      $('#payment-details-modal').modal('show');
      this.form.fill(app);
    },

    updatePaymentStatus(id, status) {
      let btn = document.getElementById('update-payment-btn');
      btn.innerHTML = `<div class="spinner-border spinner-border-sm mr-2"></div> Updating...`;
      btn.disabled = true;

      this.form.status_id = this.updatedStatusId;

      axios
        .post('api/cav-applications/update-payment-status', this.form)
        .then((res) => {
          toastr.success(res.data.msg, 'Information');
          btn.disabled = false;
          btn.innerHTML = 'Update';
          this.$emit('updateTable');
          $('#payment-details-modal').modal('hide');
        })
        .catch((err) => {
          toastr.error('Something went wrong!', 'Error');
          btn.disabled = false;
          btn.innerHTML = 'Update';
        });
    },
  },
};
</script>

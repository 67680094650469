<template>
  <div class="">
    <div class="row mb-4">
      <div class="col-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn btn-outline-secondary text-dark" @click="clear">
              <i class="bi bi-arrow-clockwise text-dark"></i>
            </button>
          </div>
          <select
            name=""
            id=""
            class="form-control"
            @change="changeInputType"
            v-model="column"
          >
            <option value="recent">Recently updated</option>
            <option value="lastname">Last Name</option>
            <option value="firstname">First Name</option>
            <option value="created_at">Date Applied</option>
            <option value="abbrev">Institution</option>
          </select>
          <input
            id="search-input"
            type="text"
            class="form-control"
            placeholder="Search"
            v-model="searchValue"
            :disabled="column == 'recent'"
          />
        </div>
      </div>

      <div class="col-auto px-2">
        <button
          @click="
            getPendingPayment({
              tableLoader: { enabled: false },
              reloadLoader: { enabled: true },
            })
          "
          class="btn btn-outline-secondary text-dark mr-2"
        >
          <i class="bi bi-arrow-repeat mr-1"></i> Reload
          <div
            v-if="reloadLoader"
            class="spinner-border spinner-border-sm mx-1"
            role="status"
          >
            <span class="invisible">Loading...</span>
          </div>
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table
        id="apps-pending-payment-table"
        class="table table-sm table-bordered table-hover"
      >
        <thead>
          <th><input type="checkbox" class="large-checkbox" disabled /></th>
          <th>APP NO</th>
          <th>NAME</th>
          <th>TYPE</th>
          <th>HEI</th>
          <th>PURPOSE</th>
          <th>DATE APPLIED</th>
          <th>CTC</th>
          <th>UPDATED BY</th>
          <th>LAST UPDATE</th>
          <th>STATUS</th>
          <th>ACTIONS</th>
        </thead>

        <tbody class="text-uppercase">
          <tr v-show="tableLoader" class="text-center">
            <td colspan="14">
              <div class="spinner-border spinner-border-sm mr-2"></div>
              Loading...
            </td>
          </tr>
          <tr v-if="!filteredApps.length && !tableLoader" class="text-center">
            <td colspan="14">No results found.</td>
          </tr>
          <tr
            v-for="app in filteredApps"
            :key="app.id"
            @click.stop="openAppDetailsModal(app)"
          >
            <td><input type="checkbox" class="large-checkbox" disabled /></td>
            <th>
              <OverdueIndicator :created_at="app.created_at">
              </OverdueIndicator>
              {{ app.id }}
            </th>

            <td class="text-left">
              {{ app.firstname }} {{ app.middlename }} {{ app.lastname }}
            </td>
            <td>{{ app.student_type }}</td>
            <td>{{ app.abbrev }}</td>
            <td>{{ app.purpose }}</td>
            <td>{{ app.created_at | dateTime }}</td>
            <td class="text-right">{{ app.ctc_qty }}</td>

            <td>
              {{ app.updated_by }}
            </td>
            <td>
              <span class="">{{ app.updated_at | duration }}</span>
            </td>
            <td>
              <span class="badge badge-danger">{{ app.status }}</span>
            </td>
            <td>
              <div class="btn-group">
                <button
                  class="btn btn-outline-secondary btn-sm"
                  @click.stop="openPaymentValidationModal(app)"
                >
                  <i class="bi bi-pencil text-primary"></i>
                </button>

                <button
                  class="btn btn-outline-secondary btn-sm"
                  @click="deleteApp(app.id, app.lastname)"
                >
                  <i class="bi bi-trash text-danger"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <PaymentValidation
      ref="PaymentValidation"
      @updateTable="
        getPendingPayment({
          tableLoader: { enabled: false },
          reloadLoader: { enabled: false },
        })
      "
    ></PaymentValidation>

    <AppDetailsModal ref="AppDetailsModal" :editable="false"></AppDetailsModal>
  </div>
</template>

<script>
import PaymentValidation from './PaymentValidation.vue';
import AppDetailsModal from './AppDetailsModal.vue';
import OverdueIndicator from './OverdueIndicator';

export default {
  name: 'PendingPayment',
  components: {
    PaymentValidation,
    AppDetailsModal,
    OverdueIndicator,
  },
  data() {
    return {
      searchValue: '',
      file: '',
      apps: [],
      currentID: '',
      tableLoader: false,
      reloadLoader: false,
      column: 'recent',
    };
  },
  methods: {
    getPendingPayment(config) {
      this.tableLoader = config.tableLoader.enabled;
      this.reloadLoader = config.reloadLoader.enabled;

      axios
        .get('/api/cav-applications/filter/8')
        .then((res) => {
          let sorted = res.data.sort((a, b) => {
            return new Date(b.updated_at) - new Date(a.updated_at);
          });
          this.apps = sorted;
          this.tableLoader = false;
          this.reloadLoader = false;
        })
        .catch((err) => {
          this.tableLoader = false;
          this.reloadLoader = false;
        });
    },
    changeInputType() {
      this.searchValue = '';
      let input = document.getElementById('search-input');
      this.column == 'created_at'
        ? (input.type = 'date')
        : (input.type = 'text');
    },
    clear() {
      this.column = '';
      this.searchValue = '';
    },

    deleteApp(appId, lastname) {
      if (
        confirm(
          `Are you sure you want to permanently delete this application? \nApp No: ${appId} \nLast Name: ${lastname.toUpperCase()}`
        )
      ) {
        axios
          .delete('api/cav-applications/' + appId)
          .then((res) => {
            this.getPendingPayment();
            toastr.success(res.data.msg, 'Information');
          })
          .catch((err) => {
            toastr.error('Something went wrong.', 'Error');
          });
      }
    },
    openPaymentValidationModal(data) {
      this.$refs.PaymentValidation.openModal(data);
    },
    openAppDetailsModal(app) {
      this.$refs.AppDetailsModal.openModal(app);
    },
  },

  computed: {
    filteredApps() {
      let temp = this.apps;
      let excluded = [
        'Rebita',
        'Caingles',
        'Makidato',
        'Gozon',
        'Canlas',
        'Lajos',
        'Cabang',
        'Japson',
        'Pupos',
      ];

      /* filter by recently updated apps excluding the names
       * ['REBITA', 'CAINGLES', 'MAKIDATO', 'GOZON']
       * added on 6/29/2022
       */
      if (this.column === 'recent') {
        temp = temp.filter((app) => {
          return !excluded.includes(app.updated_by);
        });
      }

      if (this.searchValue != '') {
        temp = temp.filter((cav) => {
          return cav[this.column]
            .toUpperCase()
            .includes(this.searchValue.toUpperCase());
        });
      }

      return temp;
    },
  },
  mounted() {
    this.getPendingPayment({
      tableLoader: { enabled: true },
      reloadLoader: { enabled: false },
    });

    this.$emit('getStats');
  },
};
</script>

<style scoped>
#apps-pending-payment-table tr {
  cursor: pointer !important;
}
</style>

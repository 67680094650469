<template>
  <div>
    <div class="dropdown">
      <button
        class="btn btn-outline-secondary text-dark dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        :disabled="!selectedApps.length"
      >
        <i class="bi bi-list-ul mr-1"></i> Actions
        <span v-show="selectedApps.length">({{ selectedApps.length }})</span>
        <div
          v-if="actionLoader"
          class="spinner-border spinner-border-sm mx-1"
          role="status"
        >
          <span class="invisible">Loading...</span>
        </div>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a
          class="dropdown-item"
          href="#"
          v-if="actions['ForPaymentValidation']"
          @click.prevent="updateStatus(2)"
          >Update status to
          <span class="badge badge-secondary mx-2">PAYMENT VALIDATION</span></a
        >

        <a
          class="dropdown-item"
          v-if="actions['ForRecordsVerification']"
          href="#"
          @click.prevent="updateStatus(3)"
          >Update status to For Verification</a
        >
        <a
          class="dropdown-item"
          v-if="actions['ForProcess']"
          href="#"
          @click.prevent="updateStatus(4)"
          >Update status to For Process</a
        >
        <a
          class="dropdown-item"
          v-if="actions['ForRelease']"
          href="#"
          @click.prevent="updateStatus(5)"
          >Update status to
          <span class="badge badge-success mx-2">FOR RELEASE</span></a
        >
        <a
          class="dropdown-item"
          v-if="actions['Release']"
          href="#"
          @click.prevent="updateStatus(6)"
          >Release</a
        >

        <a
          class="dropdown-item"
          v-if="actions['Pending']"
          href="#"
          @click.prevent="updateStatus(7)"
          >Update status to
          <span class="badge badge-danger mx-2">PENDING</span></a
        >

        <a
          class="dropdown-item disabled"
          v-if="actions['PendingPayment']"
          href="#"
          @click.prevent="updateStatus(8)"
          >Update status to Pending Payment</a
        >

        <a
          class="dropdown-item"
          v-if="actions['UpdateRemarks']"
          href="#"
          @click.prevent="showUpdateRemarksModal"
          >Update remarks</a
        >
        <a
          class="dropdown-item disabled"
          v-if="actions['BulkValidate']"
          href="#"
          @click.prevent="showUpdateRemarksModal"
          >Bulk validate</a
        >
      </div>
    </div>

    <div class="modal" id="update-remarks-modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form @submit.prevent="updateRemarks">
            <div class="modal-body">
              <div class="modal-title">
                <h5 class="font-weight-bold">Update Remarks</h5>
              </div>
              <div class="form-group mt-3">
                <table class="table table-sm table-bordered">
                  <thead>
                    <th>APP NO</th>
                    <th>HEI</th>
                    <th>NAME</th>
                  </thead>
                  <tbody>
                    <tr v-for="app in selectedApps" :key="app.id">
                      <th>{{ app.id }}</th>
                      <td>{{ app.abbrev }}</td>
                      <td>
                        {{ app.firstname }} {{ app.middlename }}
                        {{ app.lastname }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <label for="">Remarks/Deficiencies</label>
                <textarea
                  class="form-control"
                  cols="30"
                  rows="5"
                  v-model="remarks"
                  placeholder="Input any remarks or deficiencies found here"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
              <button
                id="update-remarks-btn"
                type="submit"
                class="btn btn-primary"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionsDropdown',
  data() {
    return {
      actionLoader: false,
      remarks: '',
      actions: {
        ForValidation: false,
        ForPaymenValidation: false,
        ForRecordsVerification: false,
        ForProcess: false,
        ForRelease: false,
        Release: false,
        Pending: false,
        PendingPayment: false,
        UpdateRemarks: false,
        BulkValidate: false,
      },
    };
  },
  props: {
    selectedApps: {
      required: true,
      type: Array,
    },
    statusId: {
      required: true,
      type: Number,
    },
  },
  methods: {
    updateStatus(statusId) {
      this.actionLoader = true;

      axios
        .post('api/cav-applications/bulk-status-update', {
          status_id: statusId,
          selectedApps: this.selectedApps,
        })
        .then((res) => {
          this.$emit('updateTable');
          this.actionLoader = false;
        })
        .catch((err) => {
          toastr.error('Unable to update application status.', 'Error');
          this.actionLoader = false;
        });
    },

    updateRemarks() {
      let btn = document.getElementById('update-remarks-btn');
      btn.innerHTML = `<div class="spinner-border spinner-border-sm mr-2"></div> Submitting...`;
      btn.disabled = true;

      axios
        .post('api/cav-applications/update-remarks', {
          selectedApps: this.selectedApps,
          remarks: this.remarks,
        })
        .then(() => {
          this.hideUpdateRemarksModal();
          this.remarks = '';
          btn.innerHTML = 'Submit';
          btn.disabled = false;
          this.$emit('updateTable');
        })
        .catch(() => {
          toastr.error('Unable to update remarks.', 'Error');
          btn.innerHTML = 'Submit';
          btn.disabled = false;
        });
    },

    resetAllowableActions() {
      for (const prop in this.actions) {
        this.actions[prop] = false;
      }
    },

    renderAllowableActions() {
      this.resetAllowableActions();

      if (this.statusId == 1) {
        this.actions['ForPaymentValidation'] = this.actions[
          'Pending'
        ] = this.actions['UpdateRemarks'] = true;
      }

      if (this.statusId == 2) {
        this.actions['PendingPayment'] = this.actions['BulkValidate'] = true;
      }

      if (this.statusId == 3) {
        this.actions['UpdateRemarks'] = this.actions['Pending'] = true;
      }

      if (this.statusId == 4) {
        this.actions['ForRelease'] = this.actions['Pending'] = this.actions[
          'UpdateRemarks'
        ] = true;
      }

      if (this.statusId == 5) {
        this.actions['Release'] = true;
      }

      if (this.statusId == 7) {
        this.actions['ForProcess'] = this.actions['ForRelease'] = this.actions[
          'ForPaymentValidation'
        ] = this.actions['UpdateRemarks'] = this.actions[
          'ForRecordsVerification'
        ] = true;
      }
    },

    showUpdateRemarksModal() {
      $('#update-remarks-modal').modal('show');
    },

    hideUpdateRemarksModal() {
      $('#update-remarks-modal').modal('hide');
    },
  },
  mounted() {
    this.renderAllowableActions();
  },
};
</script>

<template>
  <i
    class="bi bi-circle-fill mx-1"
    :class="overdueColor"
    style="font-size: 10px;"
  ></i>
</template>

<script>
export default {
  name: 'OverdueIndicator',
  props: {
    created_at: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {};
  },
  computed: {
    overdueColor() {
      const diffInDays = Math.ceil(
        (new Date() - new Date(this.created_at)) / (1000 * 60 * 60 * 24)
      );
      if (diffInDays > 9) {
        return 'text-danger';
      } else if (diffInDays > 5) {
        return 'text-warning';
      } else {
        return 'text-success';
      }
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('button',{staticClass:"btn btn-outline-secondary text-dark",on:{"click":_vm.clear}},[_c('i',{staticClass:"bi bi-arrow-clockwise text-dark"})])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.column),expression:"column"}],staticClass:"form-control",attrs:{"name":"","id":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.column=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.changeInputType]}},[_c('option',{attrs:{"value":"payment_updated_at"}},[_vm._v("Date Forwarded to RV")]),_c('option',{attrs:{"value":"lastname"}},[_vm._v("Last Name")]),_c('option',{attrs:{"value":"firstname"}},[_vm._v("First Name")]),_c('option',{attrs:{"value":"abbrev"}},[_vm._v("Institution")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchValue),expression:"searchValue"}],staticClass:"form-control",attrs:{"id":"search-input","type":"date","placeholder":"Search"},domProps:{"value":(_vm.searchValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchValue=$event.target.value}}})])]),_c('div',{staticClass:"col-auto px-2"},[_c('button',{staticClass:"btn btn-outline-secondary text-dark",on:{"click":function($event){return _vm.getForVerification({
            tableLoader: { enabled: false },
            reloadLoader: { enabled: true },
          })}}},[_c('i',{staticClass:"bi bi-arrow-repeat mr-1"}),_vm._v(" Reload "),(_vm.reloadLoader)?_c('div',{staticClass:"spinner-border spinner-border-sm mx-1",attrs:{"role":"status"}},[_c('span',{staticClass:"invisible"},[_vm._v("Loading...")])]):_vm._e()])]),_c('div',{staticClass:"col-auto px-2"},[_c('ActionsDropdown',{attrs:{"selectedApps":_vm.selectedApps,"statusId":3},on:{"updateTable":function($event){return _vm.getForVerification({
            tableLoader: { enabled: false },
            reloadLoader: { enabled: false },
          })}}})],1)]),_c('table',{staticClass:"table table-sm table-bordered table-hover",attrs:{"id":"apps-for-verification-table"}},[_c('thead',[_c('th',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checked),expression:"checked"}],staticClass:"large-checkbox",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.checked)?_vm._i(_vm.checked,null)>-1:(_vm.checked)},on:{"change":[function($event){var $$a=_vm.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checked=$$c}},function($event){_vm.checked ? _vm.selectAll() : _vm.unselectAll()}]}})]),_c('th',[_vm._v("APP NO")]),_c('th',[_vm._v("NAME")]),_c('th',[_vm._v("TYPE")]),_c('th',[_vm._v("HEI")]),_c('th',[_vm._v("PURPOSE")]),_c('th',[_vm._v("DATE APPLIED")]),_c('th',[_vm._v("CTC")]),_c('th',[_vm._v("AMOUNT")]),_c('th',[_vm._v("UPDATED BY")]),_c('th',[_vm._v("STATUS")])]),_c('tbody',{staticClass:"text-uppercase"},[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.tableLoader),expression:"tableLoader"}],staticClass:"text-center"},[_vm._m(0)]),(!_vm.filteredApps.length && !_vm.tableLoader)?_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"colspan":"15"}},[_vm._v("No results found.")])]):_vm._e(),_vm._l((_vm.filteredApps),function(app){return _c('tr',{key:app.id,class:{
          'table-primary text-white': _vm.selectedApps.some(
            function (obj) { return obj.id == app.id; }
          ),
        },on:{"click":function($event){$event.preventDefault();return _vm.openAppDetailsModal(app)}}},[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedApps),expression:"selectedApps"}],staticClass:"large-checkbox",attrs:{"type":"checkbox"},domProps:{"value":app,"checked":Array.isArray(_vm.selectedApps)?_vm._i(_vm.selectedApps,app)>-1:(_vm.selectedApps)},on:{"click":function($event){$event.stopPropagation();},"change":function($event){var $$a=_vm.selectedApps,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=app,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedApps=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedApps=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedApps=$$c}}}})]),_c('th',[_c('OverdueIndicator',{attrs:{"created_at":app.created_at}}),_vm._v(" "+_vm._s(app.id)+" ")],1),_c('td',{staticClass:"text-left"},[_c('a',{attrs:{"href":"#","disabled":""},on:{"click":function($event){$event.stopPropagation();return _vm.openVerifySpecialOrderModal(app)}}},[_vm._v(" "+_vm._s(app.firstname)+" "+_vm._s(app.middlename)+" "+_vm._s(app.lastname)+" ")])]),_c('td',[_vm._v(_vm._s(app.student_type))]),_c('td',[_vm._v(_vm._s(app.abbrev))]),_c('td',[_vm._v(_vm._s(app.purpose))]),_c('td',[_vm._v(_vm._s(_vm._f("dateTime")(app.created_at)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(app.ctc_qty))]),_c('td',{staticClass:"text-right"},[_vm._v("₱"+_vm._s(app.amount))]),_c('td',[_vm._v(_vm._s(app.updated_by))]),_c('td',[_c('span',{staticClass:"badge badge-secondary"},[_vm._v(_vm._s(app.status))])])])})],2)]),_c('AppDetailsModal',{ref:"AppDetailsModal",attrs:{"editable":true}}),_c('VerifySpecialOrder',{ref:"VerifySpecialOrderModal",on:{"updateTable":function($event){return _vm.getForVerification({
        tableLoader: { enabled: false },
        reloadLoader: { enabled: false },
      })}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{attrs:{"colspan":"15"}},[_c('div',{staticClass:"spinner-border spinner-border-sm mr-2"}),_vm._v(" Loading... ")])}]

export { render, staticRenderFns }
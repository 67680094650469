<template>
  <div class="">
    <div class="">
      <form @submit.prevent="search">
        <div class="input-group">
          <div class="input-group-prepend">
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="clear"
            >
              <i class="bi bi-arrow-clockwise text-dark"></i>
            </button>
          </div>
          <select
            name=""
            id=""
            class="form-control"
            v-model="params.column"
            @change="changeInputType"
          >
            <option value="" hidden selected disabled>Select column</option>
            <option value="cav.id">App No</option>
            <option value="lastname">Last Name</option>
            <option value="firstname">First Name</option>
            <option value="middlename">Middle Name</option>
            <option value="cav.created_at">Date Applied</option>
          </select>

          <input
            id="search-input"
            type="search"
            class="form-control"
            placeholder="Search"
            autocomplete="on"
            v-model="params.value"
          />

          <div class="input-group-append">
            <button
              id="cav-app-search-btn"
              type="submit"
              class="btn btn-outline-secondary"
            >
              <i class="bi bi-search text-dark"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  props: ['status'],
  data() {
    return {
      params: {
        column: 'lastname',
        value: '',
        status: this.status,
      },
    };
  },
  methods: {
    search() {
      let b = document.getElementById('cav-app-search-btn');
      b.disabled = true;
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span>`;
      axios
        .post('api/cav-applications/search', this.params)
        .then((res) => {
          this.$emit('update', res.data);
          b.disabled = false;
          b.innerHTML = `<i class="bi bi-search text-dark"></i>`;
        })
        .catch((err) => {
          b.disabled = false;
          b.innerHTML = `<i class="bi bi-search text-dark"></i>`;
          toastr.error('Something went wrong. Please try again.', 'Error');
        });
    },

    clear() {
      this.params.column = '';
      this.params.value = '';
    },

    changeInputType() {
      this.params.value = '';
      let input = document.getElementById('search-input');
      this.params.column == 'cav.created_at'
        ? (input.type = 'date')
        : (input.type = 'text');
    },
  },
  computed: {},
};
</script>
